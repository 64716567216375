<template src="./home.html" />

<script>
import { useStore } from "@/store/store.js"
import mixins from "@/mixins/mixins.js"

export default {
  mixins: [mixins],
  components: {
  },
  data() {
    return {
      data_1: "1",
      radioCheck: "noite",
      numero: null,
      complemento: null,
      cidade: null,
      estado: null,
      todayDate: null,
      today: null,
      options: {},
      users: [
        { id: "1", name: "joao" },
        { id: "1", name: "joao" },
        { id: "1", name: "joao" },
      ],
      item: [{}],
      specialities: [],
      cep: null,
      endereco: "",
      naoLocalizado: false,
      selectedSpecialitie: null,
      valMin: "",
      valMax: "",
      gettingLocation: false,
      location: {
        latitude: "",
        longitude: "",
      },
      lat: "",
      lng: "",
      validAddress: false,
      invalidFields: {
        selectedSpecialitie: false,
        cep: false,
        endereco: false,
        numero: false,
        cidade: false,
        estado: false,
      },
      matchs: [],
      msgCep: "",
      checkSpeciality: false,
      errorMessageStreet: "",
      disableStreetEdit: true,
      previousAddressStreet: "",
      marketingMessages: [],
      address: null,
    };

  },
  setup () {
    const store = useStore()

    return {
      getGoogleAddress: (data) => store.dispatch("getGoogleAddress", data),
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },
  async mounted () {
    this.isDasa = this.$session.get("isDasa")
    this.$session.remove("buildRequest")
    this.$session.remove("matches")
    await this.init()
  },
  methods: {
    init() {
      window.scrollTo(0, 0)
      this.getSpecialities()
      this.getAlerts()

      if (this.$session.get("specialty")) {
        this.selectedSpecialitie = this.$session.get("specialty")
        this.radioCheck = this.$session.get("period")
      }

      let address = this.$session.get("addressUserSet")
      if (!address || !address.zipcode) {
        address = this.$session.get("address")
      }

      if (address) {
        this.cep = address.zipcode
        this.endereco = address.address
        this.cidade = address.city
        this.estado = address.state
        this.numero = address.number
        this.complemento = address.complement
      }

      if (this.cep && !this.endereco) {
        this.getAddress()
      } else if (!this.cep) {
        this.getGeolocation()
      }
    },
    getAlerts() {
      this.axios.get(
        `${this.$store.state.filooServer}message/list/login-advertising`,
        { headers: { Authorization: this.$session.get("bearer") }}
      )
        .then(({ status, data }) => {
          if(status == 200) {
            this.marketingMessages = data.filter(m => m.active === true);
          }
        })
        .catch(function () {});
    },
    openPopupCorreios() {
      this.$bvModal.show("home-popup-correios");
    },
    async getAppointmentsDoctorsAndLocationList () {
      this.$vs.loading()

      if (!this.endereco) {
        return this.getAddressByPatient()
      }

      await this.getAppointmentsDocList()

    },
    agendaComMedico(id) {
      this.axios.post(
        `${this.$store.state.filooServer}schedules/post-indicated-doctor/${id}`,
        this.buildRequest(),
        { headers: { Authorization: this.$session.get("bearer") } }
      )
        .then(({ status }) => {
          this.$bvModal.show(`modal-${status === 201 ? "solicitation-confirmed": "home-denied"}`)
        })
        .catch(() => {
          this.$bvModal.show("modal-home-denied");
        });
    },
    getAppointmentsDocList() {
      return this.axios.post(
        `${this.$store.state.filooServer}patient/appointmentsDocList`,
        this.buildRequest(),
        { headers: { Authorization: this.$session.get("bearer") } }
      )
        .then(({ data }) => {
          this.matches = data.content;
          this.$session.set("matches", this.matches)
          this.$session.set("buildRequest", this.buildRequest())

          this.$router.push({ name: "matches" })
        })
        .catch(() => { 
          this.$bvModal.show("modal-home-denied");
        })
        .finally(()=>{ 
          this.$vs.loading.close();
        });
    },
    async getGeolocation() {
      if (!this.cep) {
        const checkGeolocation = await navigator.permissions.query({ name: "geolocation" })
      
        if (checkGeolocation.state === "denied") {
          this.errorStr = "Geolocalização não esta habilitada."

          this.$vs.notify({
            title: "Atenção",
            time: 4500,
            text: "Geolocalização desabilitada.",
            color: "warning",
            position: "top-right",
          })
        } else {
          await navigator.geolocation.getCurrentPosition(
            async ({ coords }) => {
              if (coords.latitude && coords.longitude) {
                const response = await this.getGoogleAddress({
                  lat: coords.latitude,
                  lng: coords.longitude,
                })

                if (response) {
                  console.log(response)
                  this.cep = response.address_components.find((f) => (
                    f.types.includes("postal_code"))
                  ).short_name

                  this.cidade = response.address_components.find((f) => (
                    f.types.includes("administrative_area_level_2"))
                  ).short_name

                  this.endereco = response.address_components.find((f) => (
                    f.types.includes("route"))
                  ).short_name

                  this.numero = response.address_components.find((f) => (
                    f.types.includes("street_number"))
                  ).short_name

                  this.estado = response.address_components.find((f) => (
                    f.types.includes("administrative_area_level_1"))
                  ).short_name
                }
              }
            },
            (err) => (this.errorStr = err.message)
          )
        }
        this.$vs.loading.close()
      }
    },
    async getAddress() {
      const zipcode = this.cep.replace(/\D/g, "")
      this.validAddress = zipcode && zipcode.length === 8

      if (this.validAddress) {
        const response = await this.getAddressByZipcode(zipcode)
        this.validAddress = !!response && !!response.address

        if (response) {
          this.cep = response.address.zipcode
          this.cidade = response.address.city
          this.estado = response.address.state
          this.endereco = response.address.street

          this.disableStreetEdit = !!this.endereco
          this.setLocationSession(response)
        }
      }
      
      if (!this.validAddress) {
        this.errorMessageStreet = ""

        this.invalidFields.endereco = !(
          this.$session.has("addressUserSet") && this.$session.get("addressUserSet").remount
        )

        this.endereco = this.$session.has("addressUserSet") && this.$session.get("addressUserSet").remount
          ? this.$session.get("addressUserSet").address
          : this.previousAddressStreet

        this.setSAddressUserSet(false)
      }
    },
    cepError(error) {
      const vm = this;
      vm.msgCep = "CEP inválido";
      vm.$vs.notify({
        title: "Atenção",
        time: 4500,
        text:
            "CEP não localizado ou inválido. Por favor verifique os dados digitados.",
        color: "warning",
        position: "top-right",
      });
      vm.validAddress = false;
      vm.invalidFields.cep = true;
      vm.invalidFields.cidade = true;
      vm.invalidFields.estado = true;
      vm.invalidFields.endereco = true;
      console.log({error_buscar: error});
    },
    setLocationSession(data) {
      this.location.latitude = data.lat
      this.location.longitude = data.lng
      this.$session.set("location", this.location)
    },
    buildRequest() {
      const vm = this;

      var endereco = {
        found: true,
        id: null,
        zipcode: vm.cep,
        street: vm.endereco,
        complement: vm.complemento,
        city: vm.cidade,
        state: vm.estado,
        number: vm.numero,
      };
      var dates = {
        date: new Date().toLocaleDateString("pt-BR"),
        periodo: vm.radioCheck,
        end: "",
        initial: "",
      };
      var specialty = {
        hasGender: null,
        id: vm.selectedSpecialitie.id,
        name: null,
      };
      var status = null;
      var type = null;
      var price = 0;
      var isNearFromCompany = false;
      var match = null;
      var medicalRecordId = null;
      var distance = 20000;
      var gender = undefined;
      var exams = null;
      var id = null;
      var location = {
        lat: null,
        lng: null,
      };
      if(!vm.testNullUndefinedEmpty(vm.location.latitude) &&
        !vm.testNullUndefinedEmpty(vm.location.longitude)) {
        location = {
          lat: vm.location.latitude,
          lng: vm.location.longitude,
        }
      }
      else {
        location.lat = vm.$session.get("location").latitude;
        location.lng = vm.$session.get("location").longitude;
      }
      var solicitation = {};
      solicitation.dates = [dates];
      solicitation.specialty = specialty;
      solicitation.address = endereco;
      solicitation.status = status;
      solicitation.type = type;
      solicitation.price = price;
      solicitation.isNearFromCompany = isNearFromCompany;
      solicitation.location = location;
      solicitation.match = match;
      solicitation.medicalRecordId = medicalRecordId;
      solicitation.distance = distance;
      solicitation.exams = exams;
      solicitation.gender = gender;
      solicitation.id = id;
      solicitation.customerPlanId = vm.$session.get("customerPlan").id;

      if (vm.radioCheck == "manha") {
        dates.initial = "08:00";
        dates.end = "12:00";
      } else if (vm.radioCheck == "tarde") {
        dates.initial = "12:00";
        dates.end = "21:00";
      } else {
        dates.initial = "08:00";
        dates.end = "21:00";
      }
      return solicitation;
    },

    qrcode() {
      this.axios.get(
        `${this.$store.state.filooServer}payment/qrcode/OlaValdecirTudoBom`,
        { headers: { Authorization: this.$session.get("bearer") } }
      )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validateFields() {
      this.getGeolocation()
      const addressSpaceLocation = this.endereco.indexOf(" ")
      const addressSize = this.endereco.length
      let valid = true

      if (!this.selectedSpecialitie) {
        this.invalidFields.selectedSpecialitie = true
        this.checkSpeciality = true
        valid = false
      }
      if (!this.cep) {
        this.invalidFields.cep = true
        this.invalidFields.endereco = true
        this.invalidFields.cidade = true
        this.invalidFields.estado = true
        valid = false
      }

      if (!this.endereco || !this.numero || addressSpaceLocation <= 1 || addressSpaceLocation >= addressSize - 1) {
        this.invalidFields.numero = !!this.numero
        this.errorMessageStreet = "É necessário endereço completo. Exemplo: Avenida Paulista."
        this.disableStreetEdit = true

        if (!this.endereco) {
          this.invalidFields.cep = true
          this.invalidFields.endereco = true
          this.invalidFields.cidade = true
          this.invalidFields.estado = true
        }

        valid = false
      }

      if (!valid) {
        this.$vs.notify({
          title: "Atenção",
          position: "top-right",
          text: "Campos obrigatórios não preenchidos ou inválidos",
          color: "warning",
          icon: "error",
        })
      } else {
        this.$session.set("specialty", this.selectedSpecialitie)
        this.$session.set("period", this.radioCheck)
        this.setSAddressUserSet(true)
        this.$session.remove("matches")
        this.getAppointmentsDoctorsAndLocationList()
      }
    },
    popConfirmacao() {
      const vm = this;
      if (
        vm.cep.length != 9 ||
        vm.endereco == "" ||
        vm.radioCheck == 1 ||
        vm.numero == ""
      ) {
        vm.$swal("Por favor", "Preencha todos os campos", "warning");
        return;
      }

      vm.axios
        .get(
          vm.$store.state.filooServer +
            "specialties/" +
            vm.selectedSpecialitie +
            "/price-range",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.valMin = response.data.min;
            vm.valMax = response.data.max;
            vm.$bvModal.show("modal-confirmation-success");
          } else {
            vm.$bvModal.show("modal-home-denied");
          }
        })
        .catch(function () {
          vm.$bvModal.show("modal-home-denied");
        });
    },

    solicitar() {
      const vm = this;
      var bodyParameters = vm.buildRequest();
      vm.axios
        .post(vm.$store.state.filooServer + "schedules/post", bodyParameters, {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (response.status === 201) {
            vm.$bvModal.show("modal-solicitation-confirmed");
          } else {
            vm.$bvModal.show("modal-home-denied");
          }
        })
        .catch(function () {
          vm.$bvModal.show("modal-home-denied");
        });
    },
    getSpecialities() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "specialties/doctors", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          vm.specialities = response.data;
        });
    },
    getCompanies() {
      const vm = this;
      vm.users = "teste";
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "admin/companies?page=0&size=20&sort=id,desc",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          vm.users = response.data.content;
        })
        .catch(function (error) {
          const msg = error.response.data.message;
          vm.$vs.notify({
            title: "Atenção!",
            text: msg,
            color: "warning",
            position: "top-right",
            time: 4000,
          });
          console.log(error);
        });
    },
    getAddressByPatient() {
      const vm = this;
      vm.$vs.loading();
      vm.axios
        .get(
          `${vm.$store.state.filooServer}orizon/address/${vm.$session.get(
            "id"
          )}`
        )
        .then((response) => {
          this.address = response.data[0];
          this.cep = this.address.zipcode;
          this.street = this.address.street;
          this.number = this.address.number;
          this.getAddress();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vm.$vs.loading.close();
          vm.getAppointmentsDoctorsAndLocationList();
        });
    },
    changeField(id) {
      const element = document.getElementById(id);
      element.focus();
    },
    setSAddressUserSet(state) {
      const vm = this;
      const addressUserSet = {
        zipcode: vm.cep,
        address: vm.endereco,
        number: vm.numero,
        complement: vm.complemento,
        remount: state,
      };
      vm.$session.set("addressUserSet", addressUserSet);
    },
    checkAddressStreet(){
      const vm = this;
      const addressSpaceLocation = vm.endereco.indexOf(" ");
      const addressSize = vm.endereco.length;
      if (vm.endereco === "") {
        vm.invalidFields.endereco = true;
        vm.errorMessageStreet = "Campo obrigatório";
        vm.disableStreetEdit = false;
      }
      else if (addressSpaceLocation <= 1 || addressSpaceLocation >= addressSize - 1) {
        vm.invalidFields.endereco = true;
        vm.errorMessageStreet = "É necessário endereço completo. Exemplo: Avenida Paulista.";
        vm.disableStreetEdit = false;
      }
      else {
        vm.invalidFields.endereco = false;
        vm.errorMessageStreet = "";
        vm.disableStreetEdit = false;
      }
    },
  },
  watch: {
    selectedSpecialitie (newVal) {
      const vm = this;
      if (vm.testNullUndefinedEmpty(newVal) == false) {
        vm.invalidFields.selectedSpecialitie = false;
        vm.checkSpeciality = false;
      }
    },
    numero (newVal) {
      this.invalidFields.numero = !newVal
    },
  },
};
</script>

<style lang="scss" src="./home.scss" />
